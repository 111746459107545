<template>
  <div id="app">
    <div v-if="showHeading" class="frame frame-default frame-type-text frame-layout-0 itc-rechner-heading">
      <div class="ce-inner">
        <template v-if="media.strom == 1">
          <header v-if="selectedTarif != null"><h1 class="">{{selectedTarifHeadline}} berechnen</h1></header>
          <header v-else><h1 class="">Der passende Strom-Tarif</h1></header>
          <div class="ce-main"><p>Bei uns finden Sie für Ihren Bedarf einen ausgewogenen Tarif - mit Sicherheit.</p></div>
        </template>
        <template v-if="media.gas == 1">
          <header v-if="selectedTarif != null"><h1 class="">{{selectedTarif}} berechnen</h1></header>
          <header v-else><h1 class="">Der passende Erdgas-Tarif</h1></header>
          <div class="ce-main"><p>Bei uns finden Sie für Ihren Bedarf einen ausgewogenen Tarif - mit Sicherheit.</p></div>
        </template>
        <template v-if="media.wasser == 1">
          <header><h1 class="">Wasser Tarifrechner</h1></header>
          <div class="ce-main"><p>Frisches Trinkwasser für Zweibrücken</p></div>
        </template>
      </div>
    </div>
    <div id="itc-rechner-interface" class="frame-layout-0 flex mt-8">
      <div id="itc-rechner-left" class="w-full md:w-2/3 sm:p-10 sm:pt-0 pt-0 bg-white sm:shadow-custom2 rounded z-10">
        <div class="itc-rechner-tabs mb-8 space-x-8">
          <template v-if="mediacount > 1">
            <button v-if="media.strom != null" @click="switchMedia('strom')" class="font-semibold"><span :class="[media.strom ? 'block h-1.5 bg-magenta rounded-b-sm mb-2' : 'block h-1.5 bg-transparent rounded-b-sm mb-2']" ></span><span :class="[media.strom ? 'text-magenta' : '']">Strom</span></button>
            <button v-if="media.gas != null" @click="switchMedia('gas')" class="font-semibold"><span :class="[media.gas ? 'block h-1.5 bg-magenta rounded-b-sm mb-2' : 'block h-1.5 bg-transparent rounded-b-sm mb-2']" ></span><span :class="[media.gas ? 'text-magenta' : '']">Erdgas</span></button>
            <button v-if="media.wasser != null" @click="switchMedia('wasser')" class="font-semibold"><span :class="[media.wasser ? 'block h-1.5 bg-magenta rounded-b-sm mb-2' : 'block h-1.5 bg-transparent rounded-b-sm mb-2']" ></span><span :class="[media.wasser ? 'text-magenta' : '']">Wasser</span></button>
          </template>
        </div>
        <div v-if="media.strom == 1">
          <StromRechnerUi @update-results="getproducts" :stromConsumption="stromConsumption" :postalcode="postalcode" :cities="cities" :selectedCity="selectedCity" :loading="loading" :errorMessage="errorMessage" @update-values="updateValues"/>
        </div>
        <div v-if="media.gas == 1">
          <GasRechnerUi @update-results="getproducts" :gasConsumption="gasConsumption" :kesselleistung="kesselleistung" :postalcode="postalcode" :cities="cities" :selectedCity="selectedCity" :errorMessage="errorMessage" :loading="loading" @update-values="updateValues"/>
        </div>
        <div v-if="media.wasser == 1">
          <WasserRechnerUi @update-results="getproducts" :wasserConsumption="wasserConsumption" :postalcode="postalcode" :cities="cities" :selectedCity="selectedCity" :loading="loading" :errorMessage="errorMessage"  @update-values="updateValues"/>
        </div>
      </div>
      <div id="itc-rechner-right" class="hidden md:block w-1/3 p-10 py-14 mt-8 mb-8 bg-magenta bg-overlay-gradient text-text-hell shadow-md rounded-r">
        <ul class="text-base font-medium h-full flex flex-col space-y-6">
          <template v-if="media.strom == 1">
            <li class="icon-check-solid">Vor-Ort-Beratung</li>
            <li class="icon-check-solid">Faire Preise</li>
            <li class="icon-check-solid">Kostenlose Energieberatung</li>
            <li class="icon-check-solid">Online Vertrags-verwaltung</li>
            <li class="icon-check-solid">Diverse Ökostromtarife</li>
          </template>
          <template v-if="media.gas == 1">
            <li class="icon-check-solid">Vor-Ort-Beratung</li>
            <li class="icon-check-solid">Faire Preise</li>
            <li class="icon-check-solid">Kostenlose Energieberatung</li>
            <li class="icon-check-solid">Online Vertrags-verwaltung</li>
            <li class="icon-check-solid">Ökogastarif optional</li>
          </template>
          <template v-if="media.wasser == 1">
            <li class="icon-check-solid">Vor-Ort-Beratung</li>
            <li class="icon-check-solid">Online Vertrags-verwaltung</li>
            <li class="icon-check-solid">Geprüfte Trinkwasserqualität</li>
          </template>
        </ul>
      </div>
    </div>

    <!--
    <hr>
            {{ postalcode }} (postalcode)<br>
            {{ stromConsumption }} (stromConsumption)<br>
            {{ gasConsumption }} (gasConsumption)<br>
            {{ wasserConsumption }} (wasserConsumption)<br>
            {{ kesselleistung }} (kesselleistung)<br>
    <hr> -->
    <!--
      <div v-if="tariffs && tariffs.length" class="tarifs-preishinweis container mx-auto px-5 text-center mb-16">
        Berechnet für {{ postalcode }} und {{ stromConsumption }} kWh Jahresverbrauch. (ändern)
      </div>
      -->

    <div id="tarifs-wrap" v-if="tariffs && tariffs.length" class="container mx-auto px-5 mt-8 mb-16">
      <div class="tarifs flex flex-wrap content-start ml-6">
        <div v-for="tariff in tariffs" :key=tariff.TariffName class="tarifbox mt-6 pl-6 w-full sm:w-1/2 lg:w-1/3 2xl:w-1/4" :class="[ isHighlightedTarif(tariff.TariffName) ? 'tarifbox-highlighted' : '']">
          <div class="flex flex-col min-h-full rounded-sm bg-hell-blaugrau">

            <header class="p-5 rounded-t-sm bg-blau text-text-hell">
              <h2 class="text-lg">{{ tariff.TariffName }}</h2>
            </header>

            <div class="text-center tarife">
              <div class="itc-tarif-jahreskosten tarif p-5">
                <span class="font-semibold text-3xl">{{ tariff.priceMonth }}</span> <span class="text-xl">€ / pro Monat<sup>*</sup></span>
                <div class="itc-tarif-jahreskosten">{{ tariff.priceYear }} € / <span>pro Jahr</span></div>
              </div>
            </div>

            <div class="itc-tarif-vorteile p-5 pb-0">
              <div class="text-base itc-rechner-tarif-vorteile frame">
                <ul class="ce-bullets">
                  <template v-for="(shortdesc, index) in tariff.ShortDescription.split(/\r\n|\r|\n/g)">
                    <li class="block" v-if="shortdesc" :key="index">{{ shortdesc }}</li>
                  </template>
                </ul>
              </div>
            </div>

            <div class="itc-tarif-details text-base p-5 pb-0">
              <div v-if="tariff.ContractTermDisplayValue" class="itc-tarif-laufzeit">Vertragslaufzeit: {{ tariff.ContractTermDisplayValue }}</div>
              <div v-if="tariff.CancellationPeriodDisplayValue != 0" class="itc-tarif-kuendigungsfrist">Kündigungsfrist: {{ tariff.CancellationPeriodDisplayValue }}</div>
              <!--<div class="itc-tarif-verlaengerungszeit">Verlängerungszeit: {{ tariff.TermExtensionDisplayValue }}</div>
              <div class="itc-tarif-kuendigungsziel">Kündigungsfrist: {{ tariff.CancellationPeriodDisplayValue }}</div>-->
            </div>

            <div class="itc-tarif-details p-5 pb-0 mt-auto">
              <a v-on:click="showDetails = !showDetails" class="button itc-tarif-detailbutton no-underline font-semibold text-text cursor-pointer">
              <span :class="[showDetails ? 'inline-block transform rotate-180' : 'inline-block']">
                <svg class="fill-current text-text" fill="none" height="10" viewBox="0 0 31 18" width="31" xmlns="http://www.w3.org/2000/svg"><path d="m15.1562 17.625c.4688.4688 1.125.4688 1.5938 0l13.875-13.78125c.4688-.375.4688-1.125 0-1.59375l-1.875-1.78125c-.375-.46875-1.125-.46875-1.5938 0l-11.1562 11.06245-11.25-11.06245c-.46875-.46875-1.125-.46875-1.59375 0l-1.875 1.78125c-.46875.46875-.46875 1.21875 0 1.59375z"></path></svg>
              </span>
                Preisübersicht
              </a>
              <div v-show="showDetails">

                <!-- TariffParts -->
                <template v-for="(parts, index) in tariff.TariffParts">
                  <template v-if="media.gas == 1">
                    <div v-if="parts.Type == 'AP'" class="tarif p-5" :key=parts.DisplayName>
                      <div class="itc-tarif-arbeitspreis itc-tarif-part" :class="'itc-tarif-part-' + index">
                        <span class="font-semibold">{{ parts.DisplayName }}:</span><br>
                        <TariffPrice :parts="parts.TimeSlices" :unit="parts.Unit" :consumption="gasConsumption"></TariffPrice>
                      </div>
                    </div>

                    <div v-if="parts.Type == 'GP'" class="tarif p-5" :key=parts.DisplayName>
                      <div class="itc-tarif-grundgebuehr">
                        <span class="font-semibold">{{ parts.DisplayName }}:</span><br>
                        <span class="itc-rechner-preis-grundgebuehr itc-tarif-part" :class="'itc-tarif-part-' + index">
                          <TariffPrice :parts="parts.TimeSlices" :unit="parts.Unit" :consumption="gasConsumption"></TariffPrice>
                        </span>
                      </div>
                    </div>

                    <div v-if="parts.Name == 'PERIODICAL_METER_MEASUREMENT_READING_FEE'" class="tarif p-5" :key=parts.DisplayName>
                      <div class="itc-tarif-grundgebuehr">
                        <span class="font-semibold">{{ parts.DisplayName }}:</span><br>
                        <span class="itc-rechner-preis-nennwaerme itc-tarif-part" :class="'itc-tarif-part-' + index">
                        <TariffPrice :parts="parts.TimeSlices" :unit="parts.Unit" :consumption="gasConsumption"></TariffPrice>
                      </span>
                      </div>
                    </div>

                    <div v-if="parts.Name == 'PERIODICAL_DEMAND_PRICE'" class="tarif p-5" :key=parts.DisplayName>
                      <div class="itc-tarif-messpreis">
                        <span class="font-semibold">{{ parts.DisplayName }}:</span><br>
                        <span class="itc-rechner-preis-messpreis itc-tarif-part" :class="'itc-tarif-part-' + index">
                        <TariffPrice :parts="parts.TimeSlices" :unit="parts.Unit" :consumption="gasConsumption"></TariffPrice>
                      </span>
                      </div>
                    </div>

                  </template>
                  <template v-if="media.strom == 1"> <!-- STROM  -->
                    <div v-if="parts.Type == 'AP'" class="tarif p-5" :key=parts.DisplayName>
                      <div class="itc-tarif-arbeitspreis itc-tarif-part" :class="'itc-tarif-part-' + index">
                        <span class="font-semibold">{{ parts.DisplayName }}:</span><br>
                        <TariffPrice :parts="parts.TimeSlices" :unit="parts.Unit" :consumption="stromConsumption"></TariffPrice>
                      </div>
                    </div>

                    <div v-if="parts.Type == 'GP'" class="tarif p-5" :key=parts.DisplayName>
                      <div class="itc-tarif-grundgebuehr">
                        <span class="font-semibold">{{ parts.DisplayName }}:</span><br>
                        <span class="itc-rechner-preis-grundgebuehr itc-tarif-part" :class="'itc-tarif-part-' + index">
                           <TariffPrice :parts="parts.TimeSlices" :unit="parts.Unit" :consumption="stromConsumption"></TariffPrice>
                        </span>
                      </div>
                    </div>

                  </template>

                  <template v-if="media.wasser == 1"> <!-- Wasser -->
                    <div v-if="parts.Type == 'AP'" class="tarif p-5" :key=parts.DisplayName>
                      <div class="itc-tarif-arbeitspreis itc-tarif-part" :class="'itc-tarif-part-' + index">
                        <span class="font-semibold">{{ parts.DisplayName }}:</span><br>
                        <TariffPrice :parts="parts.TimeSlices" :unit="parts.Unit" :consumption="wasserConsumption"></TariffPrice>
                      </div>
                    </div>

                    <div v-if="parts.Type == 'GP'" :key=parts.DisplayName>
                      <div class="tarif p-5">
                        <div class="itc-tarif-grundgebuehr">
                          <span class="font-semibold">{{ parts.DisplayName }}:</span><br>
                          <span class="itc-rechner-preis-grundgebuehr itc-tarif-part" :class="'itc-tarif-part-' + index">
                             <TariffPrice :parts="parts.TimeSlices" :unit="parts.Unit" :consumption="wasserConsumption"></TariffPrice>
                          </span>
                        </div>

                        <!-- QN Preise nur nach Klick anzeigen -->
                        <a :key=index v-on:click="showAllWaterPrices = !showAllWaterPrices" class="block mt-5 button itc-tarif-detailbutton text-text cursor-pointer">
                          <span v-if="!showAllWaterPrices">Weitere Grundpreise anzeigen</span>
                          <span v-else>Grundpreise ausblenden</span>
                        </a>
                        <div v-show="showAllWaterPrices" :key=index>
                          <template v-for="(qnParts, qnIndex) in tariff.TariffParts">
                            <div v-if="qnParts.Name.startsWith('QN') || qnParts.Name.startsWith('SR')" class="" :key=qnParts.DisplayName>
                              <div class="itc-tarif-grundgebuehr pt-2">
                                {{ qnParts.DisplayName }}:<br>
                                <span class="itc-rechner-preis-grundgebuehr itc-tarif-part" :class="'itc-tarif-part-' + qnIndex">
                                      <TariffPrice :parts="qnParts.TimeSlices" :unit="qnParts.Unit" :consumption="wasserConsumption"></TariffPrice>
                                    </span>
                              </div>
                            </div>
                          </template>
                        </div>

                      </div>

                    </div>

                  </template>

                </template>



              </div>
            </div>
            <a :href="tariff.createContractLink" target="_blank" class="block p-2.5 m-5 mt-5 font-semibold text-center text-text-hell no-underline rounded bg-magenta transition hover:shadow-md">Jetzt abschließen <span class="inline-block ml-3 icon-arrow-right-regular"></span></a>
          </div>
        </div>
      </div>
    </div>

    <div v-if="tariffs && tariffs.length" class="tarifs-preishinweis container mx-auto px-5 text-center">
      Alle aufgeführten Preise sind Brutto-Preise. Alle Angaben ohne Gewähr.<br>
      <sup>*</sup>Die aufgeführten monatlichen Kosten sind nicht identisch mit den Abschlägen, da diese nur 10 mal im Jahr erhoben werden.
    </div>

  </div>

</template>

<script>
import StromRechnerUi from './components/StromRechnerUi.vue'
import GasRechnerUi from './components/GasRechnerUi.vue'
import WasserRechnerUi from './components/WasserRechnerUi.vue'
import TariffPrice from './components/TariffPrice.vue'

export default {
  name: 'App',
  data() {
    return {
      loading: false,
      showHeading: false,
      errorMessage: null,
      tariffs: [],
      selectedTarif: null,
      tarifNamen: [
        'Tarif', // 0
        'Grundversorgung Eintarif (Haushalt)', // 1
        'Grundversorgung Doppeltarif (Haushalt)', // 2
        'SWZ EXTRA Öko online', // 3
        'SWZ EXTRA Öko', // 4
        'SWZ EXTRA ÖkoLokal', // 5
        'SWZ EXTRA Öko Tag & Nacht', // 6
        'SWZ EXTRA ÖkoLokal Tag & Nacht', // 7
        'SWZ EXTRA Wärmepumpe/Nachtspeicher Öko', // 8
        'SWZ EXTRA Wärmepumpe/Nachtspeicher Öko Tag & Nacht', // 9
        'SWZ EXTRA Wärmepumpe/Nachtspeicher ÖkoLokal', // 10
        'SWZ EXTRA Wärmepumpe/Nachtspeicher ÖkoLokal Tag & Nacht', // 11
        // --- GAS TARIFE
        'HG1 Haushalt', // 12
        'SWZ EXTRA online', // 13
        'SWZ EXTRA', // 14
        'SWZ EXTRA Öko', // 15
      ],
      response: null,
      consumption: 0,
      stromConsumption: 1250,
      gasConsumption: 4500,
      kesselleistung: 3,
      wasserConsumption: 42,
      postalcode: null,
      selectedCity: 'Bitte wählen',
      cities: null,
      minValid: 500,
      maxValid: 20000,
      getVerbrauch: null,
      getPlz: null,
      getOrt: null,
      stromRechnerUrl: null,
      gasRechnerUrl: null,
      wasserRechnerUrl: null,
      media: {
      },
      mediacount: 0,
      showDetails: false,
      showAllWaterPrices: false
    }
  },
  components: {
    StromRechnerUi,
    GasRechnerUi,
    WasserRechnerUi,
    TariffPrice
  },
  computed: {
    // a computed getter
    mediaConsumption: function () {
      if(this.media.strom == 1) {
        return Number(this.stromConsumption)
      } else if(this.media.gas == 1) {
        return Number(this.gasConsumption)
      } else if(this.media.wasser == 1) {
        return Number(this.wasserConsumption)
      } else {
        return Number(0)
      }
    },
    selectedTarifHeadline: function () {
      var str = this.selectedTarif;
      return str.replace(/Single/i, "Single/Family");
    }
  },
  created: function ()  {

    // Settings via script in html dom
    if(window.setMedia != null) {
      this.media = window.setMedia
    }
    if(window.showHeading != null) {
      this.showHeading = true
    }
    // URL Params
    let uri = window.location.search.substring(1);
    let params = new URLSearchParams(uri);

    // highlight tarif via param
    if(params.get("tarif") != null) {
      let id = params.get("tarif");
      if(this.tarifNamen[id] != null) {
        this.selectedTarif = this.tarifNamen[id];
      }
    }

    // If set: validate and fetch results
    if(params.get("verbrauch") != null && params.get("plz") != null) {
      if(this.validateInput(parseInt(params.get("verbrauch")), params.get("plz"))) {
        this.consumption = params.get("verbrauch")
        this.stromConsumption = params.get("verbrauch")
        this.gasConsumption = params.get("verbrauch")
        this.wasserConsumption = params.get("verbrauch")
        this.postalcode = params.get("plz")
        this.getproducts(this.getActiveMedia())
      }
    } else if(window.localStorage.getItem('tarifrechner-plz')) {
      let storedPlz = window.localStorage.getItem('tarifrechner-plz')
      if(/^[0-9]{5}$/.test(storedPlz)) {
        this.postalcode = storedPlz
      }
    }
  },
  mounted: function () {
    this.mediacount = Object.keys(this.media).length
  },
  watch: {
    postalcode: function() {
      this.cities = null
      this.errorMessage = null
    },
    selectedCity: function(val) {
      if(this.cities != null) {
        if(val != "Bitte wählen") {
          this.errorMessage = null
        } else {
          this.errorMessage = "Bitte wählen Sie noch Ihren Ort"
        }
      }
    }
  },
  methods : {
    isHighlightedTarif(tarifname) {
      if(this.selectedTarif != null) {
        if(tarifname == this.selectedTarif) {
          return true
        }
        if(tarifname == this.selectedTarif.replace(/Single/i, "Family")) {
          return true
        }
      }
      return false

    },
    outputPrice(price){
      if(price != null) {
        price = price.toFixed(2);
        return price.toString().replace(".", ",");
      }
    },
    getActiveMedia() {
      if(this.media.strom == 1) {
        return 'strom'
      } else if(this.media.gas == 1) {
        return 'gas'
      } else if(this.media.wasser == 1) {
        return 'wasser'
      } else {
        return false
      }
    },
    validateInput(verbrauch, plz) {
      if (this.getActiveMedia() == "strom") {
        return Number.isInteger(verbrauch) && verbrauch >= 500 && verbrauch <= 20000 && /^[0-9]{5}$/.test(plz)
      } else if (this.getActiveMedia() == "gas") {
        return Number.isInteger(verbrauch) && verbrauch >= 500 && verbrauch <= 100000 && /^[0-9]{5}$/.test(plz)
      } else if(this.getActiveMedia() == "wasser") {
        return Number.isInteger(verbrauch) && verbrauch >= 20 && verbrauch <= 200 && /^[0-9]{5}$/.test(plz)
      } else {
        return false
      }
    },
    updateValues(media, kwhvalue, plz, nennwaermebelastung = null, selectedCity = '') {
      this.selectedCity = selectedCity
      if(media == "strom") {
        this.stromConsumption = kwhvalue
      }
      if(media == "gas") {
        this.gasConsumption = kwhvalue
        this.kesselleistung = nennwaermebelastung
      }
      if(media == "wasser") {
        this.wasserConsumption = kwhvalue
      }
      this.postalcode = plz
    },
    switchMedia: function (medianame) {
      // set all media elements to 0
      //for (const [key, value] of Object.entries(this.media)) {
      for (const [key] of Object.entries(this.media)) {
        this.media[key] = 0
        //console.log(`${key}: ${value}`);
      }
      // set media active
      this.media[medianame] = 1
      // remove tarrifs
      this.tariffs = null
    },
    getproducts(selectedMedia) {
      this.errorMessage = false
      this.tariffs = []
      if(selectedMedia == "strom") {
        if(window.stromRechnerUrl) {
          window.location.href = window.stromRechnerUrl + '?verbrauch=' + this.mediaConsumption + '&plz=' + this.postalcode
        } else {
          this.ajaxCall('POWER', this.stromConsumption)
        }
      }
      if(selectedMedia == "gas") {
        if(window.gasRechnerUrl) {
          window.location.href = window.gasRechnerUrl + '?verbrauch=' + this.mediaConsumption + '&plz=' + this.postalcode
        } else {
          this.ajaxCall('GAS', this.gasConsumption)
        }
      }
      if(selectedMedia == "wasser") {
        if(window.wasserRechnerUrl) {
          window.location.href = window.wasserRechnerUrl + '?verbrauch=' + this.mediaConsumption + '&plz=' + this.postalcode
        } else {
          this.ajaxCall('WATER', this.wasserConsumption)
        }
      }
    },
    ajaxCall(power, consumption) {
      window.localStorage.setItem('tarifrechner-plz', this.postalcode)
      this.loading = true
      // this.$jsonp('https://kundenportal.stadtwerke-zw.de/csit/action/ajaxProductCalculatorRequest?callback=?', {
      // this.$jsonp('https://portal.stadtwerke-zw.de/powercommerce/csit3/fo/portal/ws/csProductSearch?callback=?', {
      //   callbackQuery: 'callback',
      //   callbackName: 'jsonCallback',
      //   processID: 100,
      //   postalcode: this.postalcode,
      //   selectedCityName: (this.selectedCity != "Bitte wählen") ? this.selectedCity : '',
      //   consumption: consumption,
      //   kesselLeistung: this.kesselleistung,
      //   selectedMedia: power,
      //   customerType: 'PRIVATE'
      // })
      this.$http.post('https://portal.stadtwerke-zw.de/powercommerce/csit3/fo/portal/ws/csProductSearchV2',
          {
            // callbackQuery: 'callback',
            // callbackName: 'jsonCallback',
            // selectedCityName: (this.selectedCity != "Bitte wählen") ? this.selectedCity : '',
            // kesselLeistung: this.kesselleistung,
            // processID: 100,
            // postalcode: this.postalcode,
            pointOfConsumption: {postalCode: this.postalcode, city: (this.selectedCity != "Bitte wählen") ? this.selectedCity : ''},
            consumptions: {DEFAULT_TARIFF: consumption},
            otherInputs: {NOMINAL_POWER: this.kesselleistung },
            mediaType: power,
            customerType: 'PRIVATE'
          })
          .then((response) => {

                if(response.body.suggestionType == "CITY") {
                  this.errorMessage = "Bitte wählen Sie noch Ihren Ort"
                  this.cities = response.body.suggestions
                  this.cities.unshift("Bitte wählen")
                }


                if(response.body.tariffs) {
                  let itcTariffs = response.body.tariffs
                  itcTariffs.forEach(function (item) {
                    // search for contract link
                    if(item.Links) {
                      item.Links.forEach(function (link) {
                        if(link.DocType == "CONTRACT") {
                          item.createContractLink = link.Link
                        }
                      })
                    }
                    item.showDetails = false
                    if(item.CancellationTime == "END_OF_YEAR") {
                      item.CancellationTime = "Jahresende"
                    }
                    // console.log(item.TariffParts[2].Price)

                    item.priceYearFloat = item.PriceYear;
                    item.priceYear = item.priceYearFloat.toString().replace('.', ',');
                    item.priceMonth = item.priceYearFloat.toString().replace( /,/,"." ) / 12
                    item.priceMonth = item.priceMonth.toFixed(2)
                    item.priceMonth = item.priceMonth.replace( ".",",")


                  })
                  this.tariffs = this.$set(this.tariffs, 0, itcTariffs)
                }

              }
              , response => {
                //error
                if(response.status == 400) {
                  if(response.body.status == "ERROR") {

                    if(response.body.errors[0].code == "message.INFO.ServiceReturnCodes.NO_SEARCH_RESULTS") {
                      this.errorMessage = "Leider konnten keine passenden Tarife zu Ihren Angaben ermittelt werden."
                    }
                    // if(response[0].errors[0] == 'noTariffsFound') {
                    //   this.errorMessage = "Leider konnten keine passenden Tarife zu Ihren Angaben ermittelt werden."
                    // }
                    // if(response[0].errors[0] == 'postalcode.unknown') {
                    //   this.errorMessage = "Die angegebene Postleitzahl ist unbekannt."
                    // }
                    // if(response[0].errors[0] == 'selectedCityName.required') {
                    //   this.errorMessage = "Bitte wählen Sie noch Ihren Ort"
                    //   this.cities = response[0].additionalInputs.cities
                    //   this.cities.unshift("Bitte wählen")
                    // }
                    // if(response[0].errors[0] == 'selectedCityName.unknown') {
                    //   this.errorMessage = "Ortsname unter dieser Postleitzahl unbekannt"
                    // }
                    // if(response[0].errors[0] == 'consumption.required') {
                    //   this.errorMessage = "Die Angabe des Verbrauchs wird benötigt"
                    // }
                    // if(response[0].errors[0] == 'consumption.toHigh') {
                    //   this.errorMessage = "Bitte kontaktieren Sie uns auf Grund des hohen Verbrauchs für ein individuelles Angebot"
                    // }
                    // if(response[0].errors[0] == 'street.required') {
                    //   this.errorMessage = "Die Angabe der Straße wird benötigt"
                    // }
                    // if(response[0].errors == 'noTariffsFound') {
                    //   this.errorMessage = "Leider konnten keine passenden Tarife zu Ihren Angaben ermittelt werden."
                    // }
                  }
                }
              })
          .finally(() => {
            this.loading = false
            if(this.tariffs.length > 0) {
              document.getElementById("tarifs-wrap").scrollIntoView({ behavior: 'smooth' })
            }
          })
    }
  }
}

</script>

<style>
.local #app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
</style>

<style lang="scss">
/* Set the theme color of the component */
$themeColor: #6C7F9B !default;
$disabledOpacity: 0.5 !default;

$bgColor: #C1C8CD !default;
$railBorderRadius: 15px !default;

$dotShadow: 0 !default;

#itc-rechner-submit {
  min-width: 235px;
  min-height: 50px;
  img {
    margin: 0 auto;
    height: 15px
  }
}

.itc-rechner-icon-legend {
  text-align: left;
  margin-bottom: 20px;
}
.itc-rechner-icons {
  display: flex;
  padding-bottom: 10px;
}

@media only screen and (max-width: 1023px) {
  .itc-rechner-strom .itc-rechner-icon svg,
  .itc-rechner-wasser .itc-rechner-icon svg {
    width: 24px;
  }
}

@media only screen and (max-width: 450px) {
  .itc-rechner-strom .itc-rechner-icon svg,
  .itc-rechner-wasser .itc-rechner-icon svg {
    width: 14px;
  }
}

@media only screen and (max-width: 450px) {
  .itc-rechner-gas .itc-rechner-icon svg {
    height: 55px;
    width: auto;
  }
}


.itc-rechner-errormsg {
  text-align: center;
  margin: 20px;
  div {
    background: #ff0;
    display: inline-block;
    padding: 8px 12px;
    border-radius: 4px;
    border: 3px dashed #FD0;
  }
}

.vue-slider.process-max {
  .vue-slider-dot { left: 100% !important; }
  .vue-slider-process { width: 100% !important; }
}

.vue-slider-dot-handle-custom {
  cursor: pointer;
  margin-top: -6px;
}

.vue-slider-dot:focus-visible .vue-slider-dot-handle-custom  {
    outline-style: solid;
    outline-offset: 0.2rem;
    outline-width: 0.25rem;
    border-radius: 0.1rem;
    outline-color: #384d7a;
}

.itc-rechner-inputs {
  display: flex;
  flex-wrap: wrap;
  text-align: left;
  padding-top: 20px;
  font-size: 19px;
  margin-bottom: 30px;
  label {
    display: block;
  }
  input { font-size: 19px; padding: 12px 0px 12px 12px; }
  .itc-rechner-input-kwh  {
    padding-right: 20px;
    input { width: 100px; }
  }
  .itc-rechner-input-border {
    border: 1px solid #E4E4E4;
    input { border: none;}
    span { display: inline-block; padding: 0px 0px 0px 12px;}
  }
  .itc-rechner-input-plz  {
    input {
      border: 1px solid #E4E4E4;
      width: 160px;
    }
  }
}
.itc-rechner-cities {
  margin-top: 20px;
  select {
    border: 1px solid #E4E4E4;
    font-size: 19px;
    padding: 12px;
  }
}

/*
.itc-rechner-gas {
  .itc-rechner-inputs {
    margin-bottom: 10px;
  }
}
*/


@media only screen and (max-width: 450px) {
  .itc-rechner-inputs {
    flex-wrap: wrap;
    .itc-rechner-input {
      width: 100%;
    }
    .itc-rechner-input-kwh {
      padding-right: 0;
      margin-bottom: 20px;
      .itc-rechner-input-border {
        width: 100%;
      }
      input {
        width: 75%;
      }
    }
    .itc-rechner-input-plz {
      input {
        width: 100%;
      }
    }
  }
}

.itc-rechner-icons {
  display: flex;
  padding-bottom: 10px;
  &.itc-rechner-gas-icons {
    .icon1 { width: 25%; }
    .icon2 { width: 25%; }
    .icon3 { width: 35%; }
    .icon4 { width: 35%; }
  }
  &.itc-rechner-strom-icons {
    .icon1 { width: 20%; }
    .icon2 { width: 20%; }
    .icon3 { width: 25%; }
    .icon4 { width: 35%; }
  }
  &.itc-rechner-wasser-icons {
    .icon1 { width: 20%; }
    .icon2 { width: 20%; }
    .icon3 { width: 25%; }
    .icon4 { width: 35%; }
  }
}
.itc-rechner-icon {
  text-align: center;
  cursor: pointer;
}


/* import theme style */
@import '~vue-slider-component/lib/theme/default.scss';
</style>
