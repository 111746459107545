<template>
    <div class="itc-rechner itc-rechner-gas">
        <form @submit.prevent="submit">
            <div class="itc-rechner-icon-legend">Wohnfläche in m<sup>2</sup></div>
            <div class="itc-rechner-icons itc-rechner-gas-icons">
                <button class="itc-rechner-icon icon1" @click="switchConsumption(1)" aria-label="40qm Wohnfläche" type="button">
                    <icon-base icon-name="gas1" :iconColor="iconColor1" :width="68" :height="74" viewBox="0 0 68 74"><icon-gas1 /></icon-base>
                </button>

                <button class="itc-rechner-icon icon2" @click="switchConsumption(2)" aria-label="80qm Wohnfläche" type="button">
                    <icon-base icon-name="gas2" :iconColor="iconColor2" :width="80" :height="74" viewBox="0 0 80 74"><icon-gas2 /></icon-base>
                </button>

                <button class="itc-rechner-icon icon3" @click="switchConsumption(3)" aria-label="120qm Wohnfläche" type="button">
                    <icon-base icon-name="gas3" :iconColor="iconColor3" :width="112" :height="74" viewBox="0 0 112 74"><icon-gas3 /></icon-base>
                </button>

                <button class="itc-rechner-icon icon4" @click="switchConsumption(4)" aria-label="160qm Wohnfläche" type="button">
                    <icon-base icon-name="gas4" :iconColor="iconColor4" :width="140" :height="74" viewBox="0 0 140 74"><icon-gas4 /></icon-base>
                </button>
            </div>

            <vue-slider
                v-model="kwhvalue"
                dotSize="24"
                height="11px"
                contained=true
                ref="slider"
                :silent="silent"
                :min="min"
                :max="max"
                :tooltip="errorMsg ? 'none' : 'hover'"
                :tooltip-formatter="formatter1"
                @error="error"
                @change="clearErrorMsg"
                :class="maxProcess"
                :dot-attrs="{ 'aria-label': 'Gas-Verbrauch über Slider einstellen' }"                
                >
                <template v-slot:dot="{ value, focus }">
                    <div :class="['vue-slider-dot-handle-custom', { focus }]">
                        <svg width="22" height="29" viewBox="0 0 22 29" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M22 17.8889C22 23.964 17.0751 28.8889 11 28.8889C4.92487 28.8889 0 23.964 0 17.8889C0 11.8138 11 0 11 0C11 0 22 11.8138 22 17.8889Z" fill="#334E7A"/>
                        </svg>
                    </div>
                </template>
            </vue-slider>
            <div class="itc-rechner-inputs">
                 <div class="itc-rechner-input itc-rechner-input-kwh mb-5 xs:mb-0">
                     <label for="kwhvalue">Ihr Jahresverbrauch</label>
                     <div class="itc-rechner-input-border rounded-sm mb-5">
                        <input id="kwhvalue" v-model="kwhvalue" @input="clearErrorMsg"/><span>kWh</span>
                     </div>
                     <label class="mt-2" for="nennwaerme">Nennwärmebelastung</label>
                     <div class="itc-rechner-input-border rounded-sm">
                        <input id="nennwaerme" v-model="nennwaermebelastung" @input="clearErrorMsg"/><span>kW</span>
                     </div>
                 </div>   
                 <div class="itc-rechner-input itc-rechner-input-plz">
                     <label for="postalcode">Ihre Postleitzahl</label>
                    <input id="postalcode" v-model="postalcode" @input="clearErrorMsg" class="rounded-sm"/>
                 </div>                   
                <div v-if="cities != null" class="itc-rechner-cities">
                    <label for="ort">Ort</label>          
                    <select id="ort" v-model="selectedCity">
                        <option v-for="(item, key) in cities" :value="item" :key="key">
                        {{item}}
                        </option>
                    </select>           
                </div>                       
            </div>                  
            <button id="itc-rechner-submit" class="block mx-auto px-4 py-2.5 font-semibold no-underline text-text-hell bg-blau transition hover:shadow-lg rounded" type="submit">
                <template v-if="!loading">Tarife berechnen <span class="inline-block ml-3 icon-arrow-right-regular"></span></template>
                <template v-else><img height="25" src="/fileadmin/sw/tarifrechner/dist/three-dots.svg"></template>                
            </button>
            <div v-if="errorMsg" class="itc-rechner-errormsg" role="alert" aria-live="assertive"><div><strong>Achtung: </strong>{{ errorMsg }}</div></div>
            <div v-if="errorMessage" class="itc-rechner-errormsg" role="alert" aria-live="assertive"><div><strong>Achtung: </strong>{{ errorMessage }}</div></div>
        </form>
    </div>
</template>


<script>
import IconBase from './IconBase.vue'
import IconGas1 from './icons/IconGas1.vue'
import IconGas2 from './icons/IconGas2.vue'
import IconGas3 from './icons/IconGas3.vue'
import IconGas4 from './icons/IconGas4.vue'
import VueSlider from 'vue-slider-component'
//import 'vue-slider-component/theme/default.css'
/* Set the theme color of the component */


// import { ERROR_TYPE } from 'vue-slider-component'
const ERROR_TYPE = {
  VALUE: 1,
  INTERVAL: 2,
  MIN: 3,
  MAX: 4,
  ORDER: 5,
  PLZ: 6
}

export default {
    name: 'GasRechnerUi',
    components: {
        IconBase,
        IconGas1,
        IconGas2,
        IconGas3,
        IconGas4,
        VueSlider,
    },
    data: function () {
        return {            
            iconColor1: '#334E7A',
            iconColor2: '#C1C8CD',
            iconColor3: '#C1C8CD',
            iconColor4: '#C1C8CD',
            iconColor5: '#C1C8CD',            
            min: 1000,
            max: 40000,
            errorMsg: '',
            kwhvalue: 4500,
            nennwaermebelastung: 0,
            validform: null,
            silent: true,
            maxProcess: null,
            formatter1: '{value} kWh',
        }
    },
    props: ['gasConsumption', 'postalcode', 'kesselleistung', 'cities', 'selectedCity', 'loading', 'errorMessage'],
    methods: {
        switchConsumption: function(val) {
            this.clearErrorMsg()
            if(val == 1) {
                this.kwhvalue = 4500
            }
            if(val == 2) {
                this.kwhvalue = 12500
            }
            if(val == 3) {
                this.kwhvalue = 23000
            }                      
            if(val == 4) {
                this.kwhvalue = 31000
            }             

        },
        submit: function() {
            this.validform = true;
            if(this.kwhvalue < 500) {
                this.validform = false
                this.errorMsg = 'Bitte geben Sie einen Verbrach von mind. 500 kWh an'
            }
            if(this.kwhvalue > 100000) {
                this.validform = false
                this.errorMsg = 'Kontaktieren Sie uns bei einem höheren Verbrauch als 100.000 kWh'
            }
            if(!/^[0-9]{5}$/.test(this.postalcode)) {
                this.validform = false;
                this.error(6, 'Bitte prüfen Sie die Postleitzahl')
            }
            if(!/^[0-9]{1,2}$/.test(this.nennwaermebelastung) && this.nennwaermebelastung < 1) {
                this.validform = false;
                this.error(6, 'Bitte prüfen Sie die Nennwärmebelastung')
            }            
            if(this.validform) {
                this.$emit('update-results', 'gas');       
            }
        },
        error(type, msg) {
            var msg2 = msg
            switch (type) {
                case ERROR_TYPE.MIN:
                    msg2 = ''
                break
                case ERROR_TYPE.MAX:
                    msg2 = ""
                    this.maxProcess = "process-max"
                break
                case ERROR_TYPE.VALUE:
                    msg2 = "Bitte prüfen Sie Ihre kWh Angabe"
                break
            }
    
            this.errorMsg = msg2

        },
        clearErrorMsg() {
            if(this.maxProcess != null && this.kwhvalue != null && this.kwhvalue <= this.max) {
                this.maxProcess = null
            }
            this.errorMsg = ''
        }

    },
    mounted: function() {
        this.kwhvalue = this.gasConsumption
        this.nennwaermebelastung = this.kesselleistung
    },
    watch: {
        kwhvalue: function(val) {
            if(val) {
                this.$emit('update-values', 'gas', this.kwhvalue, this.postalcode, this.nennwaermebelastung, this.selectedCity);
            }
            if(val > 0 && val < 4500) {
                this.iconColor1 = '#C1C8CD',
                this.iconColor2 = '#C1C8CD',
                this.iconColor3 = '#C1C8CD',
                this.iconColor4 = '#C1C8CD'
            }
            if(val >= 4500 && val < 12500) {
                this.iconColor1 = '#334E7A',
                this.iconColor2 = '#C1C8CD',
                this.iconColor3 = '#C1C8CD',
                this.iconColor4 = '#C1C8CD'
            }
            if(val >= 12500 && val < 23000) {
                this.iconColor1 = '#334E7A',
                this.iconColor2 = '#334E7A',
                this.iconColor3 = '#C1C8CD',
                this.iconColor4 = '#C1C8CD'
            }            
            if(val >= 23000 && val < 31000) {
                this.iconColor1 = '#334E7A',
                this.iconColor2 = '#334E7A',
                this.iconColor3 = '#334E7A',
                this.iconColor4 = '#C1C8CD'
            }              
            if(val >= 31000) {
                this.iconColor1 = '#334E7A',
                this.iconColor2 = '#334E7A',
                this.iconColor3 = '#334E7A',
                this.iconColor4 = '#334E7A'
            }  
            // nennwaerme
            this.nennwaermebelastung = Math.round(val/1600)                        
        },
        postalcode: function(val) {
            this.cities = null
            this.selectedCity = 'Bitte wählen'            
            if(val) {
                this.$emit('update-values', 'gas', this.kwhvalue, this.postalcode, this.nennwaermebelastung, this.selectedCity);
            }
        },
        selectedCity: function(val) {
            if(val) {
                this.$emit('update-values', 'strom', this.kwhvalue, this.postalcode, null, this.selectedCity);
            }
        },        
        nennwaermebelastung: function(val) {
            if(val) {
                this.$emit('update-values', 'gas', this.kwhvalue, this.postalcode, this.nennwaermebelastung, this.selectedCity);
            }
        }        
    }
}
</script>