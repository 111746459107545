import Vue from 'vue'
import App from './App.vue'


import { VueJsonp } from 'vue-jsonp'
Vue.use(VueJsonp)

import VueResource from 'vue-resource'
Vue.use(VueResource);

Vue.config.productionTip = false

Vue.use(require('vue-moment'));


/* exported vm */
const vm = new Vue({
  render: h => h(App),
}).$mount('#app')

export default vm
// Now you can use this.$jsonp in Vue components.
/*
vm.$jsonp('https://kundenportal.stadtwerke-zw.de/csit/action/ajaxProductCalculatorRequest?callback=?', {
  callbackQuery: 'callback',
  callbackName: 'jsonCallback',
  processID: 100,
  postalcode: 66482,
  consumption: 1000,  
  selectedMedia: 'POWER',
  customerType: 'PRIVATE' 
}) */