<template>
  <div>
    <!-- TariffParts > TimeSlices -->
    <template v-for="(timeslice, indexslice) in parts">
      <div v-if="timeslice.DateFrom" class="preis-ab" v-bind:key="indexslice">Preis ab {{ timeslice.DateFrom | moment("DD.MM.YYYY") }}:</div>
      <!-- TariffParts > TimeSlices > ConsumptionSlices -->
      <template v-for="(consumptionslice) in timeslice.ConsumptionSlices">
        <template v-if="consumptionslice.ConsumptionFrom <= consumption &&  consumptionslice.ConsumptionTo >= consumption">
          {{ outputPrice(consumptionslice.Price.Brutto) }} {{ unit }}
        </template>
      </template>
    </template>
  </div>
</template>

<script>
export default {
  name: "TariffPrice",
  props: {
    parts: {
      type: Object,
      required: true
    },
    consumption: {
      type: Number,
      required: true
    },
    unit: {
      type: String,
      required: true
    }
  },
  methods: {
    outputPrice(price){
      if(price != null) {
        price = price.toFixed(2);
        return price.toString().replace(".", ",");
      }
    },
  }
}
</script>

<style scoped>

</style>