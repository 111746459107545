<template>
    <div class="itc-rechner itc-rechner-wasser">
        <form @submit.prevent="submit">
            <div class="itc-rechner-icon-legend">Personen im Haushalt</div>
            <div class="itc-rechner-icons itc-rechner-wasser-icons">
                <div class="itc-rechner-icon icon1" @click="switchConsumption(1)" aria-label="Eine Person im Haushalt" role="button">
                    <icon-base icon-name="wasser-1-1" :iconColor="iconColor1"><icon-strom /></icon-base>
                </div>

                <div class="itc-rechner-icon icon2" @click="switchConsumption(2)" aria-label="Zwei Personen im Haushalt" role="button">
                    <icon-base icon-name="wassser-2-1" :iconColor="iconColor2"><icon-strom /></icon-base>
                    <icon-base icon-name="wassser-2-2" :iconColor="iconColor2"><icon-strom /></icon-base>
                </div>

                <div class="itc-rechner-icon icon3" @click="switchConsumption(3)" aria-label="Drei Personen im Haushalt" role="button">
                    <icon-base icon-name="wasser-3-1" :iconColor="iconColor3"><icon-strom /></icon-base>
                    <icon-base icon-name="wasser-3-2" :iconColor="iconColor3"><icon-strom /></icon-base>
                    <icon-base icon-name="wasser-3-3" :iconColor="iconColor3"><icon-strom /></icon-base>
                </div>

                <div class="itc-rechner-icon icon4" @click="switchConsumption(4)" aria-label="Vier Personen im Haushalt" role="button">
                    <icon-base icon-name="wasser-4-1" :iconColor="iconColor4"><icon-strom /></icon-base>
                    <icon-base icon-name="wasser-4-2" :iconColor="iconColor4"><icon-strom /></icon-base>
                    <icon-base icon-name="wasser-4-3" :iconColor="iconColor4"><icon-strom /></icon-base>
                    <icon-base icon-name="wasser-4-4" :iconColor="iconColor4"><icon-strom /></icon-base>
                </div>
            </div>

            <vue-slider
                v-model="kwhvalue"
                dotSize="24"
                height="11px"
                contained=true
                ref="slider"
                :silent="silent"
                :min="min"
                :max="max"
                :tooltip="errorMsg ? 'none' : 'hover'"
                @error="error"
                @change="clearErrorMsg"
                :class="maxProcess"
                :dot-attrs="{ 'aria-label': 'Wasser-Verbrauch über Slider einstellen' }"                
                >
                <template v-slot:dot="{ value, focus }">
                    <div :class="['vue-slider-dot-handle-custom', { focus }]">
                        <svg width="22" height="29" viewBox="0 0 22 29" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M22 17.8889C22 23.964 17.0751 28.8889 11 28.8889C4.92487 28.8889 0 23.964 0 17.8889C0 11.8138 11 0 11 0C11 0 22 11.8138 22 17.8889Z" fill="#334E7A"/>
                        </svg>
                    </div>
                </template>
            </vue-slider>
            <div class="itc-rechner-inputs">
                 <div class="itc-rechner-input itc-rechner-input-kwh">
                     <label for="kwhvalue">Ihr Jahresverbrauch</label>
                     <div class="itc-rechner-input-border rounded-sm">
                        <input id="kwhvalue" v-model="kwhvalue" @input="clearErrorMsg"/><span>m<sup>3</sup></span>
                     </div>
                 </div>   
                 <div class="itc-rechner-input itc-rechner-input-plz">
                     <label for="postalcode">Ihre Postleitzahl</label>
                    <input id="postalcode" v-model="postalcode" @input="clearErrorMsg" class="rounded-sm"/>
                 </div>                   
                <div v-if="cities != null" class="itc-rechner-cities">
                    <label for="ort">Ort</label>           
                    <select id="ort" v-model="selectedCity">
                        <option v-for="(item, key) in cities" :value="item" :key="key">
                        {{item}}
                        </option>
                    </select>           
                </div>                         
            </div>                
            <button id="itc-rechner-submit" class="block mx-auto px-4 py-2.5 font-semibold no-underline text-text-hell bg-blau transition hover:shadow-lg rounded focus:outline-none" type="submit">
                <template v-if="!loading">Tarif berechnen <span class="inline-block ml-3 icon-arrow-right-regular"></span></template>
                <template v-else><img height="25" src="/fileadmin/sw/tarifrechner/dist/three-dots.svg"></template>                
            </button>
            <div v-if="errorMsg" class="itc-rechner-errormsg"><div>{{ errorMsg }}</div></div>
            <div v-if="errorMessage" class="itc-rechner-errormsg"><div>{{ errorMessage }}</div></div>           
        </form>
    </div>
</template>


<script>
import IconBase from './IconBase.vue'
import IconStrom from './icons/IconStrom.vue'
import VueSlider from 'vue-slider-component'
//import 'vue-slider-component/theme/default.css'
/* Set the theme color of the component */


// import { ERROR_TYPE } from 'vue-slider-component'
const ERROR_TYPE = {
  VALUE: 1,
  INTERVAL: 2,
  MIN: 3,
  MAX: 4,
  ORDER: 5,
  PLZ: 6
}

export default {
    name: 'WasserRechnerUi',
    components: {
        IconBase,
        IconStrom,
        VueSlider,
    },
    data: function () {
        return {            
            iconColor1: '#C1C8CD',
            iconColor2: '#C1C8CD',
            iconColor3: '#C1C8CD',
            iconColor4: '#C1C8CD',
            iconColor5: '#C1C8CD',            
            min: 20,
            max: 200,
            errorMsg: '',
            kwhvalue: 20,
            validform: null,
            silent: true,
            maxProcess: null
        }
    },
    props: ['wasserConsumption', 'postalcode', 'cities', 'selectedCity', 'loading', 'errorMessage'],
    methods: {
        switchConsumption: function(val) {
            this.clearErrorMsg()
            if(val == 1) {
                this.kwhvalue = 42
            }
            if(val == 2) {
                this.kwhvalue = 84
            }
            if(val == 3) {
                this.kwhvalue = 126
            }                      
            if(val == 4) {
                this.kwhvalue = 168
            }             

        },
        submit: function() {
            this.validform = true;

            if(this.kwhvalue < 20) {
                this.validform = false
                this.errorMsg = 'Bitte geben Sie einen Verbrach von mind. 20 m3 an'
            }
            if(this.kwhvalue > 200) {
                this.validform = false
                this.errorMsg = 'Kontaktieren Sie uns bei einem höheren Verbrauch als 200 m3'
            }
            if(!/^[0-9]{5}$/.test(this.postalcode)) {
                this.validform = false;
                this.error(6, 'Bitte prüfen Sie die Postleitzahl')
            }
            if(this.validform) {
                this.$emit('update-results', 'wasser');       
            }
        },
        error(type, msg) {
            var msg2 = msg
            switch (type) {
                case ERROR_TYPE.MIN:
                    msg2 = ''
                break
                case ERROR_TYPE.MAX:
                    msg2 = ""
                    this.maxProcess = "process-max"
                break
                case ERROR_TYPE.VALUE:
                    msg2 = "Bitte prüfen Sie Ihre Angaben (Verbrauch)"
                break
            }
    
            this.errorMsg = msg2

        },
        clearErrorMsg() {
            if(this.maxProcess != null && this.kwhvalue != null && this.kwhvalue <= this.max) {
                this.maxProcess = null
            }
            this.errorMsg = ''
        }

    },
    mounted: function() {
        this.kwhvalue = this.wasserConsumption
    },
    watch: {
        kwhvalue: function(val) {
            if(val) {
                this.$emit('update-values', 'wasser', this.kwhvalue, this.postalcode, null, this.selectedCity);  
            }
            if(val > 0 && val < 42) {
                this.iconColor1 = '#C1C8CD',
                this.iconColor2 = '#C1C8CD',
                this.iconColor3 = '#C1C8CD',
                this.iconColor4 = '#C1C8CD'
            }
            if(val >= 42 && val < 84) {
                this.iconColor1 = '#334E7A',
                this.iconColor2 = '#C1C8CD',
                this.iconColor3 = '#C1C8CD',
                this.iconColor4 = '#C1C8CD'
            }
            if(val >= 84 && val < 126) {
                this.iconColor1 = '#334E7A',
                this.iconColor2 = '#334E7A',
                this.iconColor3 = '#C1C8CD',
                this.iconColor4 = '#C1C8CD'
            }            
            if(val >= 126 && val < 168) {
                this.iconColor1 = '#334E7A',
                this.iconColor2 = '#334E7A',
                this.iconColor3 = '#334E7A',
                this.iconColor4 = '#C1C8CD'
            }              
            if(val >= 168) {
                this.iconColor1 = '#334E7A',
                this.iconColor2 = '#334E7A',
                this.iconColor3 = '#334E7A',
                this.iconColor4 = '#334E7A  '
            }                                
        },
        postalcode: function(val) {
            this.cities = null
            this.selectedCity = 'Bitte wählen'                    
            if(val) {
                this.$emit('update-values', 'wasser', this.kwhvalue, this.postalcode, null, this.selectedCity);
            }
        },
        selectedCity: function(val) {
            if(val) {
                this.$emit('update-values', 'strom', this.kwhvalue, this.postalcode, null, this.selectedCity);
            }
        }        
    }
}
</script>
